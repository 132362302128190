export const customerInputList = [
    {
        type: "input",
        id: "client_name",
        name: "client_name",
        variant: "outlined",
        label: "ชื่อ",
        placeholder: "กรุณากรอกข้อมูล",
        require: true,
    },
    {
        type: "input",
        id: "client_lastname",
        name: "client_lastname",
        variant: "outlined",
        label: "นามสกุล",
        placeholder: "กรุณากรอกข้อมูล",
        require: true,
    },
    {
        type: "input",
        id: "phone_number",
        name: "phone_number",
        variant: "outlined",
        label: "หมายเลขโทรศัพท์",
        placeholder: "กรุณากรอกข้อมูล",
        require: true,
    },
    {
        type: "input",
        id: "tracking_number",
        name: "tracking_number",
        variant: "outlined",
        label: "หมายเลข Tracking",
        placeholder: "กรุณากรอกข้อมูล",
        require: true,
    },
    {
        type: "number",
        id: "size",
        name: "size",
        variant: "outlined",
        label: "ขนาดกล่องพัสดุ",
        placeholder: "กรุณากรอกข้อมูล",
        require: true,
    },
    {
        type: "number",
        id: "price",
        name: "price",
        variant: "outlined",
        label: "ราคา",
        placeholder: "กรุณากรอกข้อมูล",
        inputProps: { maxLength: 10 },
        require: true,
    },
    {
        type: "date",
        id: "send_date",
        name: "send_date",
        variant: "outlined",
        label: "วันที่ส่ง",
        placeholder: "กรุณากรอกข้อมูล",
        require: true,
    },
    {
        type: "date",
        id: "recive_date",
        name: "recive_date",
        variant: "outlined",
        label: "วันที่รับ",
        placeholder: "กรุณากรอกข้อมูล",
        require: true,
    },
    {
        type: "select",
        id: "status",
        name: "status",
        variant: "outlined",
        label: "สถานะจัดส่ง",
        placeholder: "กรุณาเลือกข้อมูล",
        select: {
            id: "status_select",
            list: [
                { value: "0", label: "กำลังเตรียมพัสดุ" },
                { value: "1", label: "กำลังจัดส่ง" },
                { value: "2", label: "จัดส่งแล้ว" },
            ],
        },
        require: true,
    },
];
