import { Grid, Typography, Button } from "@mui/material";
import React, { Component } from "react";
import TableComponent from "../Components/MainComponents/TableComponent/TableComponent";
import { Item } from "../Components/MainComponents/MUIComponent/Item";
import { customerColumn, headerCutomer } from "../Components/MainComponents/TableComponent/JSON/TableJson";
import FieldController from "../Components/MainComponents/FieldController/FieldController";
import { search } from "../Components/MainComponents/FieldController/JSON/HomeAdminJSON";
import { getListCustomerInfoAdmin } from "../../api/HomeAdmin";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { deleteCustomerByID } from "../../api/AddCustomer";
class HomeAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customerList: [],
            filter: "",
            id: "",
            token: window.sessionStorage.getItem("token"),
            role: JSON.parse(window.sessionStorage.getItem("data_user"))?.role,
        };
    }

    addDashPhoneNumber(phoneNumber) {
        let formattedNumber = phoneNumber.replace(/\D[^.]/g, "");
        formattedNumber = phoneNumber.slice(0, 3) + "-" + formattedNumber.slice(3, 6) + "-" + formattedNumber.slice(6);
        return formattedNumber;
    }

    onChangeText = (e) => {
        this.setState({ filter: e.target.value });
    };

    componentDidMount = () => {
        const { token, role } = this.state;
        if (!token) {
            window.location.replace("/auth")
        } else if (role === "2") {
            window.location.replace("/search-user")
        } else {
            this.onClickSearch();
        }
    };

    onClickSearch = () => {
        let filter = this.state.filter.replace(/-/g, "");
        getListCustomerInfoAdmin({ filter }).then((res) => {
            if (res.success) {
                const updatedCustomerList = res.data.map((el, i) => {
                    return this.createData(
                        i + 1,
                        el.client_name,
                        this.addDashPhoneNumber(el.phone_number),
                        el.tracking_number,
                        el.send_date === "Invalid Date" ? "-" : el.send_date,
                        el.recive_date === "Invalid Date" ? "-" : el.recive_date,
                        el.status === 0 ? "กำลังเตรียมพัสดุ" : el.status === 1 ? "กำลังจัดส่ง" : el.status === 2 ? "จัดส่งแล้ว" : "ไม่มีสถานะ",
                        "Edit",
                        "Delete",
                        el.id
                    );
                });

                // Set the state once after the loop is done
                this.setState({ customerList: updatedCustomerList });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "ผิดพลาด",
                    text: res.message,
                });
                this.setState({ customerList: [] });
            }
        });
    };

    createData(no, client_name, phone_number, tracking_number, send_date, recive_date, status, edit, remove, id) {
        return { no, client_name, phone_number, tracking_number, send_date, recive_date, status, edit, remove, id };
    }

    onClickeditButton = (id) => {
        this.props.navigate(`/edit-customer/${id}`);
    };

    onClickDelete = (id) => {
        Swal.fire({
            text: "ต้องการลบข้อมูลใช่หรือไม่",
            icon: "info",
            showDenyButton: true,
            confirmButtonText: "ตกลง",
            denyButtonText: `ยกเลิก`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCustomerByID({ id }).then((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: "success",
                            title: "ลบข้อมูลสำเร็จ",
                        });
                        this.onClickSearch();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "ผิดพลาด",
                            text: res.message,
                        });
                    }
                });
            }
        });
    };

    render() {
        const { customerList, filter } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Item>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">ค้นหาข้อมูลลูกค้า</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FieldController
                                    type={search.type}
                                    id={search.id}
                                    label={search.label}
                                    name={search.name}
                                    value={filter}
                                    placeholder={search.placeholder}
                                    variant={search.variant}
                                    sx={search.sx}
                                    onChangeText={this.onChangeText}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={this.onClickSearch}>
                                    ค้นหา
                                </Button>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <TableComponent
                        header={headerCutomer}
                        body={customerList}
                        columns={customerColumn}
                        onClickeditButton={this.onClickeditButton}
                        onClickDelete={this.onClickDelete}
                    />
                </Grid>
            </Grid>
        );
    }
}
export default function HomeAdminWrapper(props) {
    const navigate = useNavigate();
    return <HomeAdmin {...props} navigate={navigate} />;
}
