import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

export default function TableComponent({ header, body, columns, onClickeditButton, onClickDelete }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: "65vh" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {body.length === 0 ? (
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <td
                                    style={{
                                        border: "1px solid #FFFFFF",
                                        height: "30px",
                                        textAlign: "center",
                                        color: "#FF0000",
                                    }}
                                    colSpan="7"
                                >
                                    ***ไม่พบข้อมูล***
                                </td>
                            </TableRow>
                        ) : (
                            body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === "number" ? (
                                                        column.format(value)
                                                    ) : value === "Edit" ? (
                                                        <Button variant="text" onClick={() => onClickeditButton(row.id)}>
                                                            <EditIcon fontSize="14" sx={{ mr: 0.2 }} />
                                                            แก้ไข
                                                        </Button>
                                                    ) : value === "Delete" ? (
                                                        <Button variant="text" onClick={() => onClickDelete(row.id)}>
                                                            <DeleteIcon fontSize="14" sx={{ mr: 0.2 }} />
                                                            ลบ
                                                        </Button>
                                                    ) : (
                                                        value
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={body.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
