export const search = {
    type: "input",
    id: "search",
    name: "search",
    variant: "outlined",
    sx: { width: "100%" },
};

export const tableSearch = (lang) => {
    let table = {
        header: [
            { label: lang === "TH" ? "ลำดับ" : "No.", align: "center", width: 10 },
            { label: lang === "TH" ? "ชื่อ" : "Name", width: 130 },
            { label: lang === "TH" ? "เบอร์โทรศัพท์" : "Phone number", width: 125 },
            { label: lang === "TH" ? "หมายเลขพัสดุ" : "Tracking Number", width: 110 },
            { label: lang === "TH" ? "วันที่ส่ง" : "Send Date", width: 60 },
            { label: lang === "TH" ? "วันที่รับ" : "Recive Date", width: 60 },
            { label: lang === "TH" ? "สถานะ" : "Status", width: 125 },
        ],
    };

    return table;
};
