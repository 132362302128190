import { Grid, Typography, Button } from "@mui/material";
import React, { Component } from "react";
import { Item } from "../Components/MainComponents/MUIComponent/Item";
import { search_user } from "../Components/MainComponents/FieldController/JSON/SearchUser";
import FieldController from "../Components/MainComponents/FieldController/FieldController";
import TableComponent from "../Components/MainComponents/TableComponent/TableComponent";
import { headerUser, userColumn } from "../Components/MainComponents/TableComponent/JSON/TableJson";
import { deleteUserByID, getListUserAdmin } from "../../api/AddUserAPI";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

class SearchUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userList: [],
            filter: "",
            token: window.sessionStorage.getItem("token"),
            role: JSON.parse(window.sessionStorage.getItem("data_user"))?.role,
        };
    }
    componentDidMount = () => {
        const { token, role } = this.state;
        if (!token) {
            window.location.replace("/auth")
        } else if (role === "1") {
            window.location.replace("/home-admin")
        } else {
            this.onClickSearch();
        }
    };

    onClickSearch = () => {
        getListUserAdmin({ filter: this.state.filter }).then((res) => {
            if (res.success) {
                const updatedCustomerList = res.data.map((el, i) => {
                    return this.createData(
                        i + 1,
                        el.fullname_user,
                        el.role === "0" ? "ผู้ดูแลระบบ" : el.role === "1" ? "พนักงาน" : "ผู้พัฒนาระบบ",
                        el.status === "1" ? "เปิดการใช้งาน" : "ปิดการใช้งาน",
                        "Edit",
                        "Delete",
                        el.id
                    );
                });

                // Set the state once after the loop is done
                this.setState({ userList: updatedCustomerList });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "ผิดพลาด",
                    text: res.message,
                });
                this.setState({ userList: [] });
            }
        });
    };

    onChangeText = (e) => {
        this.setState({ filter: e.target.value });
    };

    onClickeditButton = (id) => {
        this.props.navigate(`/edit-user/${id}`);
    };
    onClickDelete = (id) => {
        Swal.fire({
            text: "ต้องการลบข้อมูลใช่หรือไม่",
            icon: "info",
            showDenyButton: true,
            confirmButtonText: "ตกลง",
            denyButtonText: `ยกเลิก`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserByID({ id }).then((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: "success",
                            title: "ลบข้อมูลสำเร็จ",
                        }).then((submit) => {
                            this.onClickSearch();
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "ผิดพลาด",
                            text: res.message,
                        });
                    }
                });
            }
        });
    };

    createData(no, user_name, role, status, edit, remove, id) {
        return { no, user_name, role, status, edit, remove, id };
    }
    render() {
        const { userList } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Item>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">ค้นหาข้อมูลผู้ใช้งาน</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FieldController
                                    type={search_user.type}
                                    id={search_user.id}
                                    label={search_user.label}
                                    name={search_user.name}
                                    // value={filter}
                                    placeholder={search_user.placeholder}
                                    variant={search_user.variant}
                                    onChangeText={this.onChangeText}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={this.onClickSearch}>
                                    ค้นหา
                                </Button>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <TableComponent
                        header={headerUser}
                        columns={userColumn}
                        body={userList}
                        onClickeditButton={this.onClickeditButton}
                        onClickDelete={this.onClickDelete}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default function SearchUserWrapper(props) {
    const navigate = useNavigate();
    return <SearchUser {...props} navigate={navigate} />;
}
