import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";
import banner from "../../../Assets/banner.png";
import "./LayoutStyle.css";
import Footer from "./Footer";

export default class LayoutUser extends Component {
    render() {
        return (
            <div className="body-home">
                <Container maxWidth="xl" sx={{ minHeight: "100vh", position: "relative" }}>
                    <Box sx={{ height: "20vh", paddingTop: "15px", maxHeight: "200px", marginBottom: "40px", marginTop: "20px" }}>
                        <img src={banner} alt="banner" style={{ height: "100%" }} />
                    </Box>
                    <Box sx={{ flexGrow: 1, minHeight: "63vh" , zIndex:"1" }}>
                        <Outlet />
                    </Box>
                    <Box>
                        <Footer />
                    </Box>
                </Container>
            </div>
        );
    }
}
