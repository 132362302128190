import { Button, Container, Grid, InputAdornment, TextField } from "@mui/material";
import React, { Component } from "react";
import banner from "../../Assets/login_logo.png";
import { Box } from "@mui/material";
import { Item } from "../Components/MainComponents/MUIComponent/Item";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { authentication } from "../../api/AuthAPI";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

class Authentication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            loading: false,
        };
    }

    onChangeText = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onClickLogin = () => {
        const { username, password } = this.state;
        this.setState({ loading: true });
        if (username && password) {
            authentication({ username, password }).then((res) => {
                const data = res.data;
                if (res.success) {
                    this.setState({ loading: false });
                    window.sessionStorage.setItem("data_user", JSON.stringify(data));
                    window.sessionStorage.setItem("token", res.token);
                    window.location.replace("/home-admin")
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "ผิดพลาด",
                        text: res.message,
                    });
                    this.setState({ loading: false });
                }
            });
        } else {
            this.setState({ loading: false });
            Swal.fire({
                icon: "error",
                title: "ผิดพลาด",
                text: `กรุณากรอก ${username ? "Passsword" : "Username"}`,
            });
        }
    };

    render() {
        return (
            <Container>
                <Grid
                    container
                    spacing={2}
                    columns={12}
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    sx={{ height: window.innerHeight + 15.6 }}
                >
                    <Grid item xs={10} sm={4} sx={{ display: { xs: "none", sm: "block" } }}>
                        <Grid item>
                            <Box sx={{ height: "60vh", maxHeight: "400px" }}>
                                <img src={banner} alt="banner" style={{ height: "100%" }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} sm={4}>
                        <Item style={{ background: "white", borderRadius: "10px", padding: "30px" }}>
                            <div style={{ display: "block" }}>
                                <div>
                                    <h1>Login</h1>
                                </div>
                            </div>
                            <form>
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Username"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    id="username"
                                    name="username"
                                    onChange={this.onChangeText}
                                />
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Password"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type="password"
                                    id="password"
                                    name="password"
                                    onChange={this.onChangeText}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ margin: "15px 0px 0px 0px", width: "100px" }}
                                    onClick={this.onClickLogin}
                                    disabled={this.state.loading}
                                >
                                    {this.state.loading ? <CircularProgress size={"24px"} /> : "เข้าสู่ระบบ"}
                                </Button>
                            </form>
                        </Item>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default function AuthenticationWrapper(props) {
    const navigate = useNavigate();
    return <Authentication {...props} navigate={navigate} />;
}
