import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../../Assets/banner.png";
import "./LayoutStyle.css";
import CardAdmin from "./CardAdmin";
import { Button, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 240;
const pages = [
    { label: "ค้นหาข้อมูลลูกค้า", path: "/home-admin" },
    { label: "ค้นหาข้อมูลผู้ใช้งาน", path: "/search-user" },
];

const customerButton = [
    { path: "/home-admin", label: "ค้นหาข้อมูลลูกค้า", fontSize: 19, icon: <ManageSearchIcon /> },
    { path: "/add-customer", label: "เพิ่มข้อมูลลูกค้า", fontSize: 17 },
];

const userButton = [
    { path: "/search-user", label: "ค้นหาข้อมูลผู้ใช้งาน", fontSize: 19 },
    { path: "/add-user", label: "เพิ่มข้อมูลผู้ใช้งาน", fontSize: 17 },
];

const logOut = [{ path: "/auth", label: "Log out", fontSize: 19 }];

export default function LayoutsAdmin(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [path, setPath] = React.useState("");
    const [user_fullname, setUser_fullname] = React.useState("");
    const [user_role, setUser_role] = React.useState("");

    React.useEffect(() => {
        const data_user = JSON.parse(window.sessionStorage.getItem("data_user"));
        setUser_fullname(data_user?.user_fullname);
        setUser_role(Number(data_user?.role));
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const onClickLogout = () => {
        window.sessionStorage.clear();
    };

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {customerButton.map((el, index) => (
                    <ListItem key={index} disablePadding>
                        <Link to={el.path} style={{ color: "#000000DE" }}>
                            <ListItemButton onClick={handleDrawerToggle}>
                                <ListItemText primary={el.label} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {userButton.map((el, index) => (
                    <ListItem key={index} disablePadding>
                        <Link to={el.path} style={{ color: "#000000DE" }}>
                            <ListItemButton onClick={handleDrawerToggle}>
                                <ListItemText primary={el.label} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {logOut.map((el, index) => (
                    <ListItem key={index} disablePadding>
                        <Link to={el.path} style={{ color: "#000000DE" }}>
                            <ListItemButton onClick={handleDrawerToggle}>
                                <ListItemText primary={el.label} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const onClickChangePage = async (pathname) => {
        await setPath(pathname);
    };

    return (
        <div className="body-home">
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#231f20" }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div style={{ width: "90px" }}>
                        <img src={logo} alt="logo" className="img-responsive" />
                    </div>
                    <Grid sx={{ display: { xs: "none", sm: "block" } }}>
                        {pages.map((page, idx) => {
                            let shouldRenderLink = true;

                            if (user_role === 2 && page.path === "/home-admin") {
                                shouldRenderLink = false;
                            } else if (user_role === 1 && page.path === "/search-user") {
                                shouldRenderLink = false;
                            }

                            if (shouldRenderLink) {
                                return (
                                    <Link key={idx} to={page.path} style={{ marginLeft: "20px" }}>
                                        <Button sx={{ color: "white" }} onClick={() => onClickChangePage(page.path)}>
                                            {page.label}
                                        </Button>
                                    </Link>
                                );
                            }

                            return null;
                        })}
                    </Grid>
                    <Grid flexGrow={1} />
                    <Typography variant="body2">{user_fullname}</Typography>
                    <Grid sx={{ display: { xs: "none", sm: "block" } }} ml={3}>
                        <Link to={"/auth"} onClick={onClickLogout}>
                            <IconButton size="large" aria-label="log-out" color="primary">
                                <LogoutIcon /> <Typography variant="body1">ออกจากระบบ</Typography>
                            </IconButton>
                        </Link>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Container maxWidth="xl" sx={{ minHeight: "100vh", position: "relative" }}>
                <Box sx={{ flexGrow: 1, minHeight: "63vh", zIndex: "1", pt: 3 }}>
                    <Toolbar />
                    <CardAdmin path={path} />
                </Box>
            </Container>
        </div>
    );
}
