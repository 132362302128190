import { Grid, Typography } from "@mui/material";
import React, { Component } from "react";
import logoImage from "../../../Assets/logo-icon.png";
import { language } from "../MainComponents/ChangeLanguage/ChnageLanguage";

export default class Footer extends Component {
    render() {
        return (
            <div style={{ color: "white" }}>
                <div className="py-3">
                    <Grid container spacing={1}>
                        <Grid item sm={2} padding={3} display={{ xs: "none", sm: "block" }}>
                            <img
                                className="img-fluid d-block mx-auto"
                                src="https://prajak-in.com/wp-content/uploads/2023/06/Delivery_icon.png"
                                width="90%"
                                alt="Delivery Icon"
                            />
                            <br />
                        </Grid>
                        <Grid item sm={7} xs={12} style={{ textAlign: "left" }}>
                            <Typography variant="body1">
                                <b>Found Express</b>
                            </Typography>
                            <ul className="list-unstyled" style={{ textAlign: "left" }}>
                                <li>
                                    <Typography variant="caption">{language("EN").address}</Typography>
                                </li>
                                <li>
                                    <Typography variant="caption">{language("EN").thai_phone}</Typography>
                                </li>
                                <li>
                                    <Typography variant="caption">{language("EN").laos_phone}</Typography>
                                </li>
                                <li>
                                    <Typography variant="caption">{language("EN").email}</Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item sm={3} xs={12} style={{ bottom: 0, zIndex: "1" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <button
                                        className="btn text-white"
                                        style={{ background: "#ffffff" }}
                                        onClick={() => {
                                            window.open("https://www.facebook.com/profile.php?id=100093616484180", "_blank");
                                        }}
                                    >
                                        <i className="fa fa-facebook fa-fw fa-1x" style={{ color: "#d36731", fontSize: "20px" }}></i>
                                    </button>
                                </Grid>
                                <Grid item xs={4}>
                                    <button
                                        className="btn text-white"
                                        style={{ background: "#ffffff" }}
                                        onClick={() => {
                                            window.open("https://wa.me/qr/4FPPHWLLQ7CUL1", "_blank");
                                        }}
                                    >
                                        <i className="fab fa-whatsapp fa-fw fa-1x" style={{ color: "#d36731", fontSize: "20px" }}></i>
                                    </button>
                                </Grid>
                                <Grid item xs={4}>
                                    <button
                                        className="btn text-white"
                                        style={{ background: "#ffffff" }}
                                        onClick={() => {
                                            window.open(
                                                "https://linevoom.line.me/user/_dQYtL6ghe0sgpDUxQBWUyZr-65lspXT5nXTI4SM?utm_medium=windows&utm_source=desktop&utm_campaign=Profile",
                                                "_blank"
                                            );
                                        }}
                                    >
                                        <i className="fa-brands fa-line fa-fw fa-1x" style={{ color: "#d36731", fontSize: "20px" }}></i>
                                    </button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            display={{ xs: "none", sm: "block" }}
                            style={{ width: "25%", position: "absolute", bottom: "60px", right: "0", zIndex: "0" }}
                        >
                            <img style={{ width: "100%" }} src={logoImage} alt="logoimage" />
                        </Grid>

                        <Grid item xs={12}>
                            <div className="col-md-12 text-center">
                                <p className="mb-0 mt-2">© 2023 FOUND Express. All rights reserved</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}
