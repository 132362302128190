import React, { Component } from "react";
import { Item } from "../Components/MainComponents/MUIComponent/Item";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { customerInputList } from "../Components/MainComponents/FieldController/JSON/AddCustomerJSON";
import FieldController from "../Components/MainComponents/FieldController/FieldController";
import { deleteCustomerByID, editCustomer, getCustomerByID } from "../../api/AddCustomer";
// import moment from "moment/moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ReactSignatureCanvas from "react-signature-canvas";
class EditCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                client_name: "",
                client_lastname: "",
                phone_number: "",
                tracking_number: "",
                size: "",
                price: "",
                send_date: dayjs(new Date()),
                status: "",
                sign: "",
            },
            path: window.location.pathname,
            token: window.sessionStorage.getItem("token"),
            role: JSON.parse(window.sessionStorage.getItem("data_user"))?.role,
            trimmedDataURL: "",
        };
    }

    componentDidMount = () => {
        const { token, role } = this.state;
        if (!token) {
            window.location.replace("/auth")
        } else if (role === "2") {
            window.location.replace("/search-user")
        } else {
            this.getCustomer();
        }
    };

    getCustomer = () => {
        const { path } = this.state;
        const id = path.split("/")[2];
        getCustomerByID({ id }).then((res) => {
            const data = res.data;
            if (res.success) {
                const nameParts = data.client_name.split(" ");
                let name = nameParts[0];
                let lastname = nameParts.slice(1).join(" ");
                this.clear();
                this.setState({
                    formData: {
                        id: data.id,
                        client_name: name,
                        client_lastname: lastname,
                        phone_number: data.phone_number,
                        tracking_number: data.tracking_number,
                        send_date: dayjs(data.send_date),
                        recive_date: dayjs(data.recive_date),
                        status: `${data.status}`,
                        price: data.price,
                        size: data.size,
                    },
                    trimmedDataURL: data.sign,
                });
            }
        });
    };

    onChangeText = (e) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [e.target.name]: e.target.value,
            },
        }));
    };

    onChangeDate = (e, name) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: e,
            },
        }));
    };

    onClickSave = async () => {
        const { id, client_name, client_lastname, phone_number, tracking_number, send_date, recive_date, status, price, size } = this.state.formData;
        const base64String = this.trim();

        if (client_name && client_lastname && phone_number && tracking_number && status && price && size) {
            // if (phone_number.length === 10) {
            editCustomer({
                id,
                client_name: client_name.split(" ").join(""),
                client_lastname: client_lastname.split(" ").join(""),
                phone_number,
                tracking_number,
                send_date: dayjs(send_date).format("MM/DD/YYYY"),
                recive_date: dayjs(recive_date).format("MM/DD/YYYY"),
                status,
                price,
                size,
                sign: base64String,
            }).then((res) => {
                if (res.success) {
                    Swal.fire({
                        icon: "success",
                        title: "บันทึกสำเร็จ",
                    }).then((submit) => {
                        if (status === "2") {
                            // Regex expression to remove all characters which are NOT alphanumeric
                            let number = phone_number.replace(/[^\w\s]/gi, "").replace(/ /g, "");

                            // Appending the phone number to the URL
                            let url = `https://web.whatsapp.com/send?phone=${number}`;

                            const configMessage = `ສະບາຍດີລູກຄ້າ, \nພັດສະດຸຂອງທ່ານມາຮອດວຽງຈັນແລ້ວ. ກະລຸນາແຈ້ງເບີໂທ ຫລື ເລກຕິດຕາມພັດສະດຸເພື່ອຮັບເຄື່ອງທີ່ ບ້ານ ສ້າງເຫວີຍ, ເມືອງສີສັດຕະນາກ ນະຄອນຫລວງວຽງຈັນ. ເປີດຮ້ານ 9:00-18:00 ທ່ານສາມາດຕິດຕາມພັດສະດຸຂອງທ່ານໄດ້ທີ່ລິ້ງ: https://found-express.com \nDear customer, \nYour parcel has  arrived in Vientiane. Please tell us your phone No. or Tracking Number to pick up your parcel at Ban Sangwei, Sisattanak District, vientiane Capital. Open 9:00-18:00 You can check the status of your parcel at Link: https://found-express.com`;

                            url += `&text=${encodeURI(configMessage)}&app_absent=0`;

                            // Open our newly created URL in a new tab to send the message
                            window.open(url);

                            this.getCustomer();
                        } else {
                            this.getCustomer();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "ผิดพลาด",
                        text: res.message,
                    });
                }
            });
            // } else {
            //     Swal.fire({
            //         icon: "error",
            //         title: "ผิดพลาด",
            //         text: "กรุณากรอกหมายเลขโทรศัพท์ให้ครบ 10 ตัวอักษร",
            //     });
            // }
        } else {
            Swal.fire({
                icon: "error",
                title: "ผิดพลาด",
                text: "กรุณากรอกข้อมูลให้ครบถ้วน",
            });
        }
    };

    onClickDelete = () => {
        Swal.fire({
            text: "ต้องการลบข้อมูลใช่หรือไม่",
            icon: "info",
            showDenyButton: true,
            confirmButtonText: "ตกลง",
            denyButtonText: `ยกเลิก`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCustomerByID({ id: this.state.formData.id }).then((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: "success",
                            title: "ลบข้อมูลสำเร็จ",
                        }).then((submit) => {
                            this.props.navigate("/home-admin");
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "ผิดพลาด",
                            text: res.message,
                        });
                    }
                });
            }
        });
    };

    sigPad = {};

    clear = () => {
        this.sigPad.clear();
        this.setState({ trimmedDataURL: "" });
    };
    trim = () => {
        const trimmedDataURL = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
        return trimmedDataURL;
    };

    render() {
        let { formData, trimmedDataURL } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Item>
                        <Typography color="primary" mt={3} variant="h6" gutterBottom>
                            แก้ไขข้อมูลลูกค้า
                        </Typography>
                        <Grid container spacing={4} mt={1} px={3}>
                            {customerInputList.map((el, i) => (
                                <Grid key={i} item xs={12} md={6}>
                                    <FieldController
                                        type={el.type}
                                        id={el.id}
                                        label={el.label}
                                        name={el.name}
                                        value={formData[el.name] || ""}
                                        placeholder={el.placeholder}
                                        variant={el.variant}
                                        select={el.select}
                                        onChangeText={this.onChangeText}
                                        onChangeDate={(e) => this.onChangeDate(e, el.name)}
                                        inputProps={el.inputProps}
                                        require={el.require}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={12} mt={3} px={3}>
                                <Typography variant="h6" mb={1}>
                                    ลายเซ็นต์ลูกค้า
                                </Typography>
                                <div className="center-container">
                                    <div className="signature-container">
                                        <ReactSignatureCanvas
                                            penColor="black"
                                            canvasProps={{ height: 200, className: "sigCanvas" }}
                                            ref={(ref) => {
                                                this.sigPad = ref;
                                            }}
                                        />
                                        {document.getElementById("react-container")}
                                        {trimmedDataURL && <img src={trimmedDataURL} alt="trimImg" className="signature-image" />}
                                    </div>
                                </div>

                                <Grid item xs={12} mb={5}>
                                    <Stack direction="row" spacing={2} style={{ justifyContent: "flex-end" }}>
                                        <Button color="error" variant="outlined" onClick={this.clear}>
                                            Clear
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid my={3} px={3}>
                            <Stack direction="row" spacing={2} style={{ justifyContent: "flex-end" }}>
                                <Button variant="contained" onClick={this.onClickSave}>
                                    บันทึก
                                </Button>
                                <Button color="error" variant="outlined" onClick={this.onClickDelete}>
                                    ลบ
                                </Button>
                            </Stack>
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
        );
    }
}

export default function AddCustomerWrapper(props) {
    const navigate = useNavigate();
    return <EditCustomer {...props} navigate={navigate} />;
}
