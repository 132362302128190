import axios from "axios";
import { host } from "../Environments/env";

const axiosInstance = axios.create();

const token = sessionStorage.getItem("token");
if (token) {
    axiosInstance.defaults.headers.common["token"] = token;
}

export const getListCustomerInfoAdmin = ({ filter }) => {
    return axiosInstance
        .get(`${host}/getListCustomerAdmin`, { params: { filter: filter } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};


