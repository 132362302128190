import React, { Component } from "react";
import { Item } from "../Components/MainComponents/MUIComponent/Item";
import { Button, Grid, Stack, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { userAuthList, userInputList } from "../Components/MainComponents/FieldController/JSON/AddUserJSON";
import FieldController from "../Components/MainComponents/FieldController/FieldController";
import Checkbox from "@mui/material/Checkbox";
import { deleteUserByID, editUser, getUserByID } from "../../api/AddUserAPI";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: { status: false, user_name: "", user_lastname: "", role: "", username: "", password_first: "", password_second: "" },
            path: window.location.pathname,
            token: window.sessionStorage.getItem("token"),
            role: JSON.parse(window.sessionStorage.getItem("data_user"))?.role,
        };
    }

    componentDidMount = () => {
        const { path, token, role } = this.state;
        if (!token) {
            window.location.replace("/auth")
        } else if (role === "1") {
            window.location.replace("/home-admin")
        } else {
            const id = path.split("/")[2];
            this.onGetUser(id);
        }
    };

    onGetUser = (id) => {
        getUserByID({ id }).then((res) => {
            const data = res.data;
            if (res.success) {
                const nameParts = data.fullname_user.split(" ");
                let name = nameParts[0];
                let lastname = nameParts.slice(1).join(" ");
                this.setState({
                    formData: {
                        id: data.id,
                        user_name: name,
                        user_lastname: lastname,
                        role: data.role,
                        username: data.username,
                        status: data.status === "1" ? true : false,
                    },
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "ผิดพลาด",
                    text: res.message,
                });
            }
        });
    };

    onChangeText = (e) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [e.target.name]: e.target.value,
            },
        }));
    };

    onClickSave = () => {
        const { formData, path } = this.state;
        if (formData.user_name && formData.user_lastname && formData.role && formData.username) {
            if (formData.password_first === formData.password_second) {
                editUser({ formData: formData }).then((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: "success",
                            title: "บันทึกสำเร็จ",
                        }).then((submit) => {
                            const id = path.split("/")[2];
                            this.onGetUser(id);
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "ผิดพลาด",
                            text: res.message,
                        });
                    }
                });
            } else {
                Swal.fire({
                    แicon: "error",
                    title: "ผิดพลาด",
                    text: "รหัสผ่านไม่ตรงกันกรุณากรอกใหม่อีกครั้ง",
                });
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "ผิดพลาด",
                text: "กรุณากรอกข้อมูลให้ครบถ้วน",
            });
        }
    };

    onClickDelete = () => {
        Swal.fire({
            text: "ต้องการลบข้อมูลใช่หรือไม่",
            icon: "info",
            showDenyButton: true,
            confirmButtonText: "ตกลง",
            denyButtonText: `ยกเลิก`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserByID({ id: this.state.formData.id }).then((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: "success",
                            title: "ลบข้อมูลสำเร็จ",
                        }).then((submit) => {
                            this.props.navigate("/home-admin");
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "ผิดพลาด",
                            text: res.message,
                        });
                    }
                });
            }
        });
    };

    render() {
        const { formData } = this.state;
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Item>
                        <Typography color="primary" mt={3} variant="h6" gutterBottom>
                            แก้ไขข้อมูลผู้ใช้
                        </Typography>
                        <Grid container spacing={4} mt={1} px={3}>
                            {userInputList.map((el, i) => (
                                <Grid key={i} item xs={12} md={6}>
                                    <FieldController
                                        type={el.type}
                                        id={el.id}
                                        label={el.label}
                                        name={el.name}
                                        value={formData[el.name] || ""}
                                        placeholder={el.placeholder}
                                        variant={el.variant}
                                        select={el.select}
                                        onChangeText={this.onChangeText}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={12} md={6}></Grid>
                            {userAuthList.map((el, i) => (
                                <Grid key={i} item xs={12} md={6}>
                                    <FieldController
                                        type={el.type}
                                        id={el.id}
                                        label={el.label}
                                        name={el.name}
                                        value={formData[el.name] || ""}
                                        placeholder={el.placeholder}
                                        variant={el.variant}
                                        select={el.select}
                                        onChangeText={this.onChangeText}
                                    />
                                </Grid>
                            ))}
                            <Grid item xs={12} md={6}></Grid>
                            <Grid item>
                                <Stack direction="row" spacing={2} style={{ justifyContent: "flex-start" }}>
                                    <Typography variant="body1" gutterBottom>
                                        สถานะ
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} style={{ justifyContent: "flex-start" }}>
                                    <Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.status}
                                                    onChange={(e) => {
                                                        this.setState({ formData: { ...formData, status: e.target.checked } });
                                                    }}
                                                />
                                            }
                                            label="สถานะการใช้งาน"
                                        />
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid my={3} px={3}>
                            <Stack direction="row" spacing={2} style={{ justifyContent: "flex-end" }}>
                                <Button variant="contained" onClick={this.onClickSave}>
                                    บันทึก
                                </Button>
                                <Button color="error" variant="outlined" onClick={this.onClickDelete}>
                                    ลบ
                                </Button>
                            </Stack>
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
        );
    }
}

export default function AddUserWrapper(props) {
    const navigate = useNavigate();
    return <EditUser {...props} navigate={navigate} />;
}
