import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeUser from "../Pages/User/HomeUser";
import HomeAdmin from "../Pages/Admin/HomeAdmin";
import LayoutsAdmin from "../Pages/Components/Layouts/LayoutsAdmin";
import NotFound from "../Pages/NotFound";
import LayoutUser from "../Pages/Components/Layouts/LayoutUser";
import AddCustomer from "../Pages/Admin/AddCustomer";
import SearchUser from "../Pages/Admin/SearchUser";
import AddUser from "../Pages/Admin/AddUser";
import Authentication from "../Pages/Admin/Authentication";
import LayOutAuth from "../Pages/Components/Layouts/LayOutAuth";
import EditUser from "../Pages/Admin/EditUser";
import EditCustomer from "../Pages/Admin/EditCustomer";

function Routers() {
    return (
        <BrowserRouter>
            <Routes>
                {/* authen part */}
                <Route path="/" element={<LayOutAuth />}>
                    <Route path="auth" element={<Authentication />} />
                </Route>
                {/* admin part */}
                <Route path="/" element={<LayoutsAdmin />}>
                    <Route path="home-admin" element={<HomeAdmin />} />
                    <Route path="add-customer" element={<AddCustomer />} />
                    <Route path="edit-customer/:id" element={<EditCustomer />} />
                    <Route path="search-user" element={<SearchUser />} />
                    <Route path="add-user" element={<AddUser />} />
                    <Route path="edit-user/:id" element={<EditUser />} />
                </Route>
                {/* user part */}
                <Route path="/" element={<LayoutUser />}>
                    <Route index element={<HomeUser />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Routers;
