import axios from "axios";
import { host } from "../Environments/env";

export const getListCustomerInfo = ({ filter, lang }) => {
    return axios
        .get(`${host}/getListCustomerInfo`, { params: { filter: filter, lang: lang } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
