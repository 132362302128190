import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";

export default function FieldController({
    type,
    id,
    name,
    value,
    label,
    placeholder,
    variant,
    select,
    onChangeText,
    onChangeDate,
    inputProps,
    require,
}) {
    return (
        <>
            {type === "input" || type === "password" || type === "number" ? (
                <TextField
                    type={type}
                    id={id}
                    name={name}
                    value={value}
                    label={label}
                    placeholder={placeholder}
                    variant={variant}
                    onChange={onChangeText}
                    inputProps={inputProps}
                    required={require}
                    maxLength={10}
                    fullWidth
                />
            ) : type === "date" ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label={label} value={value} onChange={onChangeDate} sx={{ width: "100%" }} defaultValue={dayjs(new Date())} />
                </LocalizationProvider>
            ) : type === "select" ? (
                <FormControl fullWidth>
                    <InputLabel id={id}>{label}</InputLabel>
                    <Select
                        id={select.id}
                        value={value}
                        label={label}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChangeText}
                        sx={{ textAlign: "left" }}
                    >
                        {select.list.map((el, i) => (
                            <MenuItem key={i} value={el.value}>
                                {el.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                ""
            )}
        </>
    );
}
