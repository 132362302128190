import React, { Component } from "react";
import "./LayoutStyle.css";
import { Outlet } from "react-router-dom";

export default class LayOutAuth extends Component {
    render() {
        return (
            <div className="body-home">
                <Outlet />
            </div>
        );
    }
}
