import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import * as React from "react";
import { language } from "../ChangeLanguage/ChnageLanguage";

export default function TableGird({ header, body, lang }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: "max-content" }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {header.map((item, i) => (
                            <TableCell key={i} align={item.align ? item.align : "left"} sx={{ width: item.width }}>
                                {item.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {body.length === 0 ? (
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <td
                                style={{
                                    border: "1px solid #FFFFFF",
                                    height: "30px",
                                    textAlign: "center",
                                    color: "#FF0000",
                                }}
                                colSpan="7"
                            >
                                {language(lang).notfound_info}
                            </td>
                        </TableRow>
                    ) : (
                        body.map((row, i) => (
                            <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                {row.map((item, idx) => (
                                    <TableCell key={idx} component="th" scope="row" align={item.align ? item.align : "left"}>
                                        {item.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
