import axios from "axios";
import { host } from "../Environments/env";

// Create an instance of Axios with common headers
const axiosInstance = axios.create();

const token = sessionStorage.getItem("token");
if (token) {
    axiosInstance.defaults.headers.common["token"] = token;
}

export const getListUserAdmin = ({ filter }) => {
    return axiosInstance
        .get(`${host}/getListUserAdmin`, { params: { filter: filter } })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const getUserByID = ({ id }) => {
    return axiosInstance
        .post(`${host}/getUserByID`, { id })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const editUser = ({ formData }) => {
    return axiosInstance
        .post(`${host}/editUser`, {
            formData,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const deleteUserByID = ({ id }) => {
    return axiosInstance
        .post(`${host}/deleteUserByID`, { id })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
