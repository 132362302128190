import axios from "axios";
import { host } from "../Environments/env";

const axiosInstance = axios.create();

const token = sessionStorage.getItem("token");
if (token) {
    axiosInstance.defaults.headers.common["token"] = token;
}

export const getCustomerByID = ({ id }) => {
    return axiosInstance
        .post(`${host}/getCustomerByID`, { id })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const editCustomer = ({
    id,
    client_name,
    client_lastname,
    phone_number,
    tracking_number,
    send_date,
    recive_date,
    status,
    price,
    size,
    sign,
}) => {
    return axiosInstance
        .post(`${host}/editCustomer`, {
            id,
            client_name,
            client_lastname,
            phone_number,
            tracking_number,
            send_date,
            recive_date,
            status,
            price,
            size,
            sign,
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

export const deleteCustomerByID = ({ id }) => {
    return axiosInstance
        .post(`${host}/deleteCustomerByID`, { id })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};
