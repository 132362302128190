import axios from "axios";
import { host } from "../Environments/env";

// const refreshToken = sessionStorage.getItem("token");

export const authentication = ({ username, password }) => {
    return axios
        .post(`${host}/login`, { username, password })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

// export const logout = () => {
//     return axios
//         .post(`${host}/logout`, { refreshToken: refreshToken })
//         .then((response) => {
//             return response.data;
//         })
//         .catch((error) => {
//             throw error;
//         });
// };
