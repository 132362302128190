import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { Component } from "react";
import { Item } from "../Components/MainComponents/MUIComponent/Item";
import FieldController from "../Components/MainComponents/FieldController/FieldController";
import { search, tableSearch } from "../Components/MainComponents/FieldController/JSON/HomeUserJSON";
import TableGird from "../Components/MainComponents/FieldController/TableGrid";
import { getListCustomerInfo } from "../../api/HomeUserApi";
import moment from "moment/moment";
import Swal from "sweetalert2";
import kerry from "../../Assets/logo/Logo_Kerry.png";
import dhl from "../../Assets/logo/Logo_DHL.png";
import thaipost from "../../Assets/logo/Logo_ThaiPost.png";
import ENG from "../../Assets/logo/Logo_ENG.png";
import TH from "../../Assets/logo/Logo_TH.png";
import { language } from "../Components/MainComponents/ChangeLanguage/ChnageLanguage";
import { Link } from "react-router-dom";

const ImageJson = [
    { image: thaipost, alt: "thaipost", path: "https://track.thailandpost.co.th/" },
    { image: kerry, alt: "kerry", path: "https://th.kerryexpress.com/th/track/" },
    { image: dhl, alt: "dhl", path: "https://www.dhl.com/th-th/home/tracking.html" },
];
export default class HomeUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listUsers: [],
            filter: "",
            languageSeleted: "TH",
        };
    }
    onClickSearch = () => {
        const { languageSeleted } = this.state;
        let filter = this.state.filter.replace(/-/g, "");
        getListCustomerInfo({ filter: filter, lang: languageSeleted })
            .then((res) => {
                if (res.success) {
                    let body = res.data.map((el, i) => {
                        return [
                            { label: i + 1, align: "center" },
                            { label: el.client_name },
                            { label: this.addDashPhoneNumber(el.phone_number) },
                            { label: el.tracking_number },
                            { label: el.send_date === "Invalid Date" ? "-" : moment(el.send_date).format("DD/MM/YYYY") },
                            { label: el.recive_date === "Invalid Date" ? "-" : moment(el.recive_date).format("DD/MM/YYYY") },
                            {
                                label:
                                    el.status === 0
                                        ? languageSeleted === "TH"
                                            ? "กำลังเตรียมพัสดุ"
                                            : "Preparing the parcel"
                                        : el.status === 1
                                        ? languageSeleted === "TH"
                                            ? "กำลังจัดส่ง"
                                            : "Parcel is on delivery"
                                        : el.status === 2
                                        ? languageSeleted === "TH"
                                            ? "จัดส่งแล้ว"
                                            : "Parcel has been delivered"
                                        : languageSeleted === "TH"
                                        ? "ไม่มีสถานะ"
                                        : "Don't have status",
                            },
                        ];
                    });
                    this.setState({ listUsers: body });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: language(languageSeleted).error,
                        text: res.message,
                    });
                    this.setState({ listUsers: [] });
                }
            })
            .catch((error) => {
                // Handle any errors
                console.log("Error:", error);
            });
    };

    addDashPhoneNumber(phoneNumber) {
        let formattedNumber = phoneNumber.replace(/\D[^.]/g, "");
        formattedNumber = phoneNumber.slice(0, 3) + "-" + formattedNumber.slice(3, 6) + "-" + formattedNumber.slice(6);
        return formattedNumber;
    }

    onClickClear = () => {
        this.setState({ listUsers: [], filter: "" });
    };

    onChangeText = (e) => {
        this.setState({ filter: e.target.value });
    };

    onClickPortal = (path) => {
        window.open(path);
    };

    imageGrid = () => {
        return (
            <>
                {ImageJson.map((el) => (
                    <Grid item xs={6} sm={12} key={el.alt}>
                        <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                            <div onClick={() => this.onClickPortal(el.path)} style={{ cursor: "pointer" }}>
                                <img src={el.image} alt={el.alt} style={{ width: "100%" }} />
                            </div>
                        </div>
                    </Grid>
                ))}
                <Grid item xs={6} sm={12}>
                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw" }}>
                        <Link to={"/auth"}>
                            <Button fullWidth variant="outlined">
                                log in
                            </Button>
                        </Link>
                    </div>
                </Grid>
            </>
        );
    };

    onClickChangeLanguage = (lang) => {
        this.setState({ languageSeleted: lang });
    };

    render() {
        const { listUsers, filter, languageSeleted } = this.state;
        return (
            <Grid container spacing={3} paddingTop={2}>
                <Grid item xs={12} sm={3} zIndex={1} display={{ xs: "none", sm: "block" }}>
                    <Item className="item-style-add">
                        <Grid container spacing={1}>
                            {this.imageGrid()}
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={9} zIndex={1}>
                    <Item className="item-style-add">
                        <Grid container spacing={2}>
                            <Grid container item spacing={1} justifyContent={"end"}>
                                <Grid item>
                                    <Typography variant="body1">{languageSeleted}</Typography>
                                </Grid>
                                <Grid item width={"40px"}>
                                    <img
                                        src={TH}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.onClickChangeLanguage("TH")}
                                        alt="TH"
                                        className="image-responsive"
                                    />
                                </Grid>
                                <Grid item width={"40px"}>
                                    <img
                                        src={ENG}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.onClickChangeLanguage("EN")}
                                        alt="ENG"
                                        className="image-responsive"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FieldController
                                    type={search.type}
                                    id={search.id}
                                    label={language(languageSeleted).search}
                                    name={search.name}
                                    value={filter}
                                    placeholder={language(languageSeleted).search_place}
                                    variant={search.variant}
                                    onChangeText={this.onChangeText}
                                />
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" onClick={() => this.onClickSearch()}>
                                        {language(languageSeleted).search}
                                    </Button>
                                    <Button variant="outlined" onClick={() => this.onClickClear()}>
                                        {language(languageSeleted).clear}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <TableGird header={tableSearch(languageSeleted).header} body={listUsers} lang={languageSeleted} />
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Grid item xs={12} sm={3} zIndex={1} display={{ xs: "block", sm: "none" }}>
                    <Item className="item-style-add-mobile">
                        <Grid container spacing={1}>
                            {this.imageGrid()}
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
        );
    }
}
