import { Grid } from "@mui/material";
import React, { Component } from "react";
import { Link, Outlet } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";

const TextButton = styled(Button)(() => ({
    padding: 0,
    backgroundColor: "#fff",
    "&:hover": {
        backgroundColor: "#CCC",
    },
}));

const customerButton = [
    { path: "/home-admin", label: "ค้นหาข้อมูลลูกค้า", fontSize: 19 },
    { path: "/add-customer", label: "เพิ่มข้อมูลลูกค้า", fontSize: 17 },
];

const userButton = [
    { path: "/search-user", label: "ค้นหาข้อมูลผู้ใช้งาน", fontSize: 19 },
    { path: "/add-user", label: "เพิ่มข้อมูลผู้ใช้งาน", fontSize: 17 },
];

export default class CardAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pathname: window.location.pathname,
            user_info: JSON.parse(window.sessionStorage.getItem("data_user")),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.path !== this.props.path) {
            this.setState({ pathname: window.location.pathname });
        }
    }

    onClickChangePath = (path) => {
        this.setState({ pathname: path });
    };

    render() {
        const { pathname, user_info } = this.state;
        return (
            <Grid container>
                <Grid item container xs={12} sx={{ bgcolor: "#fff", minHeight: "90vh", borderRadius: 3 }}>
                    <Grid item sm={4} md={3} borderRight={"1px solid #CCC"} p={3} sx={{ display: { xs: "none", sm: "block" } }}>
                        <Grid container spacing={1}>
                            {(user_info?.role === "0" || user_info?.role === "1") &&
                                customerButton.map((el, i) => (
                                    <Grid item key={i} xs={12} display={"flex"}>
                                        <Link to={el.path}>
                                            <TextButton
                                                variant="text"
                                                style={{ fontSize: el.fontSize, color: el.path === pathname ? "#d36731" : "#000" }}
                                                onClick={() => this.onClickChangePath(el.path)}
                                            >
                                                {el.label}
                                            </TextButton>
                                        </Link>
                                    </Grid>
                                ))}
                        </Grid>
                        {(user_info?.role === "0" || user_info?.role === "1") && <div className="center-div"></div>}

                        <Grid container spacing={1}>
                            {(user_info?.role === "0" || user_info?.role === "2") &&
                                userButton.map((el, i) => (
                                    <Grid item key={i} xs={12} display={"flex"}>
                                        <Link to={el.path}>
                                            <TextButton
                                                variant="text"
                                                style={{
                                                    fontSize: el.fontSize,
                                                    color: el.path === pathname ? "#d36731" : "#000",
                                                }}
                                                onClick={() => this.onClickChangePath(el.path)}
                                            >
                                                {el.label}
                                            </TextButton>
                                        </Link>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} p={3}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
