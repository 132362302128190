export const headerCutomer = [
    { id: "no", label: "ลำดับ", minWidth: 50 },
    { id: "client_name", label: "ชื่อ - นามสกุล", minWidth: 170 },
    { id: "phone_number", label: "เบอร์โทรศัพท์", minWidth: 150 },
    { id: "tracking_number", label: "หมายเลขพัสดุ", minWidth: 130 },
    { id: "send_date", label: "วันที่ส่ง", minWidth: 120 },
    { id: "recive_date", label: "วันที่รับ", minWidth: 120 },
    { id: "status", label: "สถานะการจัดส่ง", minWidth: 140 },
    { id: "edit", label: "แก้ไข", minWidth: 80 },
    { id: "remove", label: "ลบ", minWidth: 50 },
];

export const customerColumn = [
    { id: "no", label: "ลำดับ", minWidth: 50 },
    { id: "client_name", label: "ชื่อ - นามสกุล", minWidth: 170 },
    { id: "phone_number", label: "เบอร์โทรศัพท์", minWidth: 150 },
    { id: "tracking_number", label: "หมายเลขพัสดุ", minWidth: 130 },
    { id: "send_date", label: "วันที่ส่ง", minWidth: 120 },
    { id: "recive_date", label: "วันที่รับ", minWidth: 120 },
    { id: "status", label: "สถานะการจัดส่ง", minWidth: 140 },
    { id: "edit", label: "แก้ไข", minWidth: 50 },
    { id: "remove", label: "ลบ", minWidth: 50 },
];

export const headerUser = [
    { id: "no", label: "ลำดับ", minWidth: 50 },
    { id: "user_name", label: "ชื่อ - นามสกุล", minWidth: 170 },
    { id: "role", label: "ตำแหน่ง", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    { id: "edit", label: "แก้ไข", minWidth: 50 },
    { id: "remove", label: "ลบ", minWidth: 50 },
];

export const userColumn = [
    { id: "no", label: "ลำดับ", minWidth: 50 },
    { id: "user_name", label: "ชื่อ - นามสกุล", minWidth: 170 },
    { id: "role", label: "ตำแหน่ง", minWidth: 100 },
    { id: "status", label: "สถานะ", minWidth: 100 },
    { id: "edit", label: "แก้ไข", minWidth: 50 },
    { id: "remove", label: "ลบ", minWidth: 50 },
];
