export const TH = {
    search: "ค้นหา",
    search_place: "ค้นหาหมายเลข Tracking, ชื่อ-นามสกุล, เบอร์โทรศัพท์ ได้",
    clear: "ล้างข้อมูล",
    error: "ผิดพลาด",
    notfound_info: "*** ไม่พบข้อมูล ***",
    address: "ที่อยู่ 252 หมู่ 4 ตำบลโพธิ์ชัย 43000.",
    thai_phone: "เบอร์ไทย : 096-395-9888",
    laos_phone: "เบอร์ลาว : 030-510-1096",
    email: "อีเมลล์ : found.2024@gmail.com",
};

export const EN = {
    search: "Search",
    search_place: "Search from Tracking number, Name-Lastname, Phone number",
    clear: "Clear",
    error: "Error",
    notfound_info: "*** Not found information ***",
    address: "Address : 252 Moo 4 Pho Chai Subdistrict 43000.",
    thai_phone: "Thai Phone : 096-395-9888",
    laos_phone: "Laos Phone : 030-510-1096",
    email: "Email : found.2024@gmail.com",
};
