export const userInputList = [
    {
        type: "input",
        id: "user_name",
        name: "user_name",
        variant: "outlined",
        label: "ชื่อ",
        placeholder: "กรุณากรอกข้อมูล",
    },
    {
        type: "input",
        id: "user_lastname",
        name: "user_lastname",
        variant: "outlined",
        label: "นามสกุล",
        placeholder: "กรุณากรอกข้อมูล",
    },
    {
        type: "select",
        id: "role",
        name: "role",
        variant: "outlined",
        label: "ตำแหน่ง",
        placeholder: "กรุณาเลือกข้อมูล",
        select: {
            id: "role_select",
            list: [
                { value: "0", label: "ผู้ดูแลระบบ" },
                { value: "1", label: "พนักงาน" },
                { value: "2", label: "ผู้พัฒนาระบบ" },
            ],
        },
    },
];

export const userAuthList = [
    {
        type: "input",
        id: "username",
        name: "username",
        variant: "outlined",
        label: "ชื่อผู้ใช้งาน",
        placeholder: "กรุณากรอกข้อมูล",
    },
    {
        type: "password",
        id: "password_first",
        name: "password_first",
        variant: "outlined",
        label: "รหัสผ่าน",
        placeholder: "กรุณากรอกข้อมูล",
    },
    {
        type: "password",
        id: "password_second",
        name: "password_second",
        variant: "outlined",
        label: "ยืนยันรหัสผ่าน",
        placeholder: "กรุณากรอกข้อมูล",
    },
];
