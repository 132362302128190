import React, { Component } from "react";
import { Item } from "../Components/MainComponents/MUIComponent/Item";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { customerInputList } from "../Components/MainComponents/FieldController/JSON/AddCustomerJSON";
import FieldController from "../Components/MainComponents/FieldController/FieldController";
import { deleteCustomerByID, editCustomer, getCustomerByID } from "../../api/AddCustomer";
// import moment from "moment/moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
class AddCustomer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                client_name: "",
                client_lastname: "",
                phone_number: "",
                tracking_number: "",
                size: "",
                price: "",
                send_date: dayjs(new Date()),
                status: "",
            },
            path: window.location.pathname,
            token: window.sessionStorage.getItem("token"),
            role: JSON.parse(window.sessionStorage.getItem("data_user"))?.role,
        };
    }

    componentDidMount = () => {
        const { path, token, role } = this.state;
        if (!token) {
            window.location.replace("/auth")
        } else if (role === "2") {
            window.location.replace("/search-user")
        } else {
            if (path !== "/add-customer") {
                this.getCustomer();
            } else {
                this.setState({
                    formData: {
                        client_name: "",
                        client_lastname: "",
                        phone_number: "",
                        tracking_number: "",
                        size: "",
                        price: "",
                        send_date: dayjs(new Date()),
                        status: "",
                    },
                });
            }
        }
    };

    getCustomer = () => {
        const { path } = this.state;
        const id = path.split("/")[2];
        getCustomerByID({ id }).then((res) => {
            const data = res.data;
            if (res.success) {
                const nameParts = data.client_name.split(" ");
                let name = nameParts[0];
                let lastname = nameParts.slice(1).join(" ");
                this.setState({
                    formData: {
                        id: data.id,
                        client_name: name,
                        client_lastname: lastname,
                        phone_number: data.phone_number,
                        tracking_number: data.tracking_number,
                        send_date: dayjs(data.send_date),
                        recive_date: dayjs(data.recive_date),
                        status: `${data.status}`,
                        price: data.price,
                        size: data.size,
                    },
                });
            }
        });
    };

    onChangeText = (e) => {
        // if (e.target.name === "phone_number") {
        //     if (e.target.value.length <= 10) {
        //         this.setState({
        //             formData: {
        //                 ...this.state.formData,
        //                 phone_number: e.target.value,
        //             },
        //         });
        //     }
        // } else {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [e.target.name]: e.target.value,
            },
        }));
        // }
    };

    onChangeDate = (e, name) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: e,
            },
        }));
    };

    onClickSave = () => {
        const { path } = this.state;
        const { id, client_name, client_lastname, phone_number, tracking_number, send_date, recive_date, status, price, size } = this.state.formData;
        if (client_name && client_lastname && phone_number && tracking_number && status && price && size) {
            editCustomer({
                id,
                client_name: client_name.split(" ").join(""),
                client_lastname: client_lastname.split(" ").join(""),
                phone_number,
                tracking_number,
                send_date: dayjs(send_date).format("MM/DD/YYYY"),
                recive_date: recive_date ? dayjs(recive_date).format("MM/DD/YYYY") : "",
                status,
                price,
                size,
            }).then((res) => {
                if (res.success) {
                    Swal.fire({
                        icon: "success",
                        title: "บันทึกสำเร็จ",
                    }).then((submit) => {
                        if (path === "/add-customer") {
                            this.setState({
                                formData: {
                                    client_name: "",
                                    client_lastname: "",
                                    phone_number: "",
                                    tracking_number: "",
                                    size: "",
                                    price: "",
                                    send_date: dayjs(new Date()),
                                    status: "",
                                },
                            });
                        } else {
                            this.getCustomer();
                        }
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "ผิดพลาด",
                        text: res.message,
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "ผิดพลาด",
                text: "กรุณากรอกข้อมูลให้ครบถ้วน",
            });
        }
    };

    onClickDelete = () => {
        Swal.fire({
            text: "ต้องการลบข้อมูลใช่หรือไม่",
            icon: "info",
            showDenyButton: true,
            confirmButtonText: "ตกลง",
            denyButtonText: `ยกเลิก`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCustomerByID({ id: this.state.formData.id }).then((res) => {
                    if (res.success) {
                        Swal.fire({
                            icon: "success",
                            title: "ลบข้อมูลสำเร็จ",
                        }).then((submit) => {
                            this.props.navigate("/home-admin");
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "ผิดพลาด",
                            text: res.message,
                        });
                    }
                });
            }
        });
    };

    render() {
        const { formData, path } = this.state;

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Item>
                        <Typography color="primary" mt={3} variant="h6" gutterBottom>
                            {path !== "/add-customer" ? "แก้ไขข้อมูลลูกค้า" : "เพิ่มข้อมูลลูกค้า"}
                        </Typography>
                        <Grid container spacing={4} mt={1} px={3}>
                            {customerInputList.map((el, i) => (
                                <Grid key={i} item xs={12} md={6}>
                                    <FieldController
                                        type={el.type}
                                        id={el.id}
                                        label={el.label}
                                        name={el.name}
                                        value={formData[el.name] || ""}
                                        placeholder={el.placeholder}
                                        variant={el.variant}
                                        select={el.select}
                                        onChangeText={this.onChangeText}
                                        onChangeDate={(e) => this.onChangeDate(e, el.name)}
                                        inputProps={el.inputProps}
                                        require={el.require}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid my={3} px={3}>
                            <Stack direction="row" spacing={2} style={{ justifyContent: "flex-end" }}>
                                <Button variant="contained" onClick={this.onClickSave}>
                                    บันทึก
                                </Button>
                                {path !== "/add-customer" && (
                                    <Button color="error" variant="outlined" onClick={this.onClickDelete}>
                                        ลบ
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
        );
    }
}

export default function AddCustomerWrapper(props) {
    const navigate = useNavigate();
    return <AddCustomer {...props} navigate={navigate} />;
}
